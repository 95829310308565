span.text-price,
h1.number-picker__top--textFont36,
h1.number-picker__top--textFont45 {
    font-size: 32px !important;
}

@media (max-width: 992px) {
    span.text-price,
    h1.number-picker__top--textFont36,
    h1.number-picker__top--textFont45 {
        font-size: 28px !important;
    }
}